import React from 'react';
import FormStatusProvider from './src/providers/ordering/OrderStep';
import CartCounterProvider from './src/providers/cart-counter/CartCounter';

/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it
import './src/styles/global.css';

export const wrapRootElement = ({ element }) => {
  return (
    <FormStatusProvider>
      <CartCounterProvider>{element}</CartCounterProvider>
    </FormStatusProvider>
  );
};
